import React from "react"
import { Email } from "react-obfuscate-email"
import HubspotForm from "react-hubspot-form"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPageTemplate = ({ pageContext }) => {
  const seoTitle = (pageContext.seo) ? pageContext.seo.seoTitle : pageContext.title
  const seoDesc = (pageContext.seo) ? pageContext.seo.seoDescription : ''
  const featuredImageUrl = (pageContext.featuredImage?.formats?.large) ? pageContext.featuredImage.formats.large.url : pageContext.featuredImage.url
  const cdnUrl = 'https://dlyog079eaher.cloudfront.net';

  let formId = null
  if (pageContext.form) {
    const split1 = pageContext.form.split('formId:')
    const split2 = split1[1].split("\n")
    formId = split2[0].replaceAll('"', '')
  }

  return (
    <Layout classNames="page-template contact-us">
      <Seo title={ seoTitle } seoTitle={ seoTitle } seoDescription={ seoDesc } />
      <section className="banner-wrap">
        <div id="banner" className="banner">
          <div className="banner-bg-image col-12 ph0 small" style={{backgroundImage: `url("${featuredImageUrl.includes(cdnUrl) ? featuredImageUrl : cdnUrl + featuredImageUrl }")`, opacity: 1}}>
          </div>
          <div className="banner-overlay vert-gradient" />
          <div className="banner-inside aic jcfs">
            <div className="col-12 col-sm-8 col-md-8 banner-text m-left text-left blue-overlay vert-gradient" dangerouslySetInnerHTML={{ __html: pageContext.heroContent }} />
          </div>
        </div>
      </section>
      <section className="content-wrap">
        <div className="container">
          <div className="form_with_content_block pb5">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6 no-gutter-lg-left subform sub78">
                  <div className="form_with_content_block__form-wrap">
                    {(pageContext.form && formId) &&
                      <div className="pt5 ph6 pb5 tac">
                        <HubspotForm
                          portalId="2718424"
                          css=""
                          cssClass="hbspt-form"
                          region="na1"
                          formId={ formId }
                          loading={ <div className="form-loading"><h3>Loading...</h3></div> }
                        />
                      </div>
                    }
                  </div>
                </div>
                <div className="col-12 col-lg-6 contact-info ph0">
                  <div className="contact-general col-12 col-sm-6 col-lg-12 col-xl-6 offset-lg-2">
                    <h2 className="form_with_content_block__content-title mt4 mb4">General</h2>
                    {pageContext.generalPhone &&
                      <a href={ `tel:${pageContext.generalPhone}` } title="General Phone Number" className="contact contact-phone">{ pageContext.generalPhone }</a>
                    }
                    {pageContext.generalEmail &&
                      <Email bcc={ pageContext.generalEmail } email={ pageContext.generalEmail } title="General Email Address" className="contact contact-email">
                        { pageContext.generalEmail }
                      </Email>
                    }
                    {pageContext.generalMediaEmail &&
                      <>
                        <span className="ft3">For media inquiries:</span>
                        <Email bcc={ pageContext.generalMediaEmail } email={ pageContext.generalMediaEmail } title="General Email Address" className="contact contact-email">
                          { pageContext.generalMediaEmail }
                        </Email>
                      </>
                    }
                    {pageContext.generalAddress &&
                      <span className="contact contact-location" dangerouslySetInnerHTML={{ __html: pageContext.generalAddress }} />
                    }
                  </div>
                  <div className="contact-support col-12 col-sm-6 col-lg-12 col-xl-4 col-xxl-6 offset-xl-0 offset-lg-2">
                    <h2 className="form_with_content_block__content-title mt4 mb4">Support</h2>
                    {pageContext.supportPhone &&
                      <a href={ `tel:${pageContext.supportPhone}` } title="Support Phone Number" className="contact contact-phone">{ pageContext.supportPhone }</a>
                    }
                    {pageContext.supportEmail &&
                      <Email bcc={ pageContext.supportEmail } email={ pageContext.supportEmail } title="General Email Address" className="contact contact-email">
                    { pageContext.supportEmail }
                      </Email>
                    }
                    {pageContext.supportRequest &&
                      <a href={ pageContext.supportRequest } className="contact contact-form" title="Support Form Link" target="_blank">hc1 Support Request Form</a>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPageTemplate
